.clientArea {
  background-color: white;
  margin-top: 100px;
  margin-left: 250px;
  margin-right: 0px;
}

.formArea {
  margin-top: 0px;
  background-color: white;
}

.row {
  display: grid;
  grid-template-columns: 500px 500px;
}

.rowsingle {
  display: grid;
  grid-template-columns: 500px;
}

.rowdouble {
  display: grid;
  grid-template-columns: 1000px;
}

.rowdoublecentered {
  width: 1000px;
  text-align: center;
  justify-content: center;
}

/* Create two equal columns that floats next to each other */
.column {
  background-color: #f1f9f6;
  padding: 10px;
  vertical-align: middle;
}

.columnbutton {
  background-color: #f1f9f6;
  padding: 10px;
  vertical-align: middle;
}

.buttonClassBlue {
  background-color: blue;
  color: white;
  margin-left: 70px;
  width: 250px;

  height: 50px;
  padding: 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 15px;
}

.buttonClassGray {
  background-color: gray;
  color: white;
  margin-left: 70px;
  width: 250px;

  height: 50px;
  padding: 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 15px;
}

.buttonClassGrayMedium {
  background-color: gray;
  color: white;
  margin-left: 70px;
  width: 350px;

  height: 50px;
  padding: 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 15px;
}

.buttonClassGreen {
  background-color: green;
  color: white;
  margin-left: 70px;
  width: 250px;

  height: 50px;
  padding: 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 15px;
}

.buttonClassRed {
  background-color: red;
  color: white;
  margin-left: 70px;
  width: 250px;

  height: 50px;
  padding: 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 15px;
}

.longButtonClassGray {
  background-color: gray;
  color: white;
  margin-left: 70px;
  width: 500px;

  height: 50px;
  padding: 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 15px;
}

.longButtonClassGrayNoPadding {
  background-color: gray;
  color: white;
  width: 500px;

  height: 50px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.spaceDiv {
  height: 15px;
}

.imgtoolbar {
  cursor: pointer;
}

.imgdownload {
  cursor: pointer;
  vertical-align:middle;
}

.tdleft {
  text-align: right;
}

.tdrigth {
  text-align: left;
  padding-left: 10px;
}

.labelred {
  color: red;
}

.labelorange {
  color: orange;
}

.labelbrown {
  color: brown;
}