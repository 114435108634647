:root{
    --white: #efefef;
    --black: #36383F;
    --gray: #85888C;
} /* variables*/

/* Style the footer with menu on the right side */
.footer {
    background-color: var(--white);
    text-align: right;
    padding: 10px;
}

.footer-menu ul {
    list-style: none;
}

.footer-menu li {
    display: inline;
    margin-left: 5px;
    margin-right: 5px;
}

.footer-menu a {
    text-decoration: none;
    color: black;
}

/* Toggle menu icon */
.side-menu:checked ~ nav{
    max-height: 100%;
}

/* Add responsive design for different screen sizes */
@media screen and (max-width: 600px) {
    .footer-menu {
        text-align: center;
    }

    .footer-menu ul {
        padding-top: 20px;
    }

    .footer-menu li {
        display: block;
        margin-bottom: 10px;
    }
}

