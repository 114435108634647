* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  font-family: "Arial";
}

.home,
.home2,
.home3 {
  display: flex;
  height: 90hv;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

/* Logo */
.logo-at{
  display: inline-block;
  margin: 10px;
  padding-top: 10px;
  /* width: 20%; */
  /* height: auto; */
}

.listaddresses,
.listclients,
.listcompanies,
.listcompanybranches,
.listcounties,
.listcountries,
.listequipments,
.listequipmentdetails,
.listoperators,
.listpersons,
.listtowns,
.listiscir,
.listcncir,
.listworkers,
.ApplyCustomCss {
  margin-top: 50px;
  margin-left: 300px;
}

.AddAddress,
.AddClient,
.AddCompany,
.AddCompanyBranch,
.AddCountry,
.AddCounty,
.AddEquipment,
.AddEquipmentDetail,
.AddOperator,
.AddWorker,
.AddPerson,
.AddTown,
.AddIscir,
.AddCncir {
  display: flex;
  align-items: center;
  justify-content: center;
}

form {
  margin-top: 50px;
  background-color: #f1f9f6;
  padding: 15px;
}

h1 {
  text-align: center;
}

.card {
  width: 98%;
  border-radius: 10px;
  margin-bottom: 1rem;
}

.cardDropDown {
  width: 98%;
}

.cardBig {
  width: 95%;
  border-radius: 10px;
  margin-bottom: 1rem;
}

.cardDropDownBig {
  width: 95%;
}

.tableReport {
  border: 1px solid;
}

.columnReport {
  border: 1px solid;
  padding: 10px;
}

.hideMe {
  visibility: hidden;
}

.showMe {
  visibility: visible;
}