
:root{
    --white: #f9f9f9;
    --black: #36383F;
    --gray: #85888C;
} /* variables*/

/* Reset */
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


a{
    text-decoration: none;
}

ul{
    list-style: none;
}/* CSS Document */

/* Header */
.header{
    background-color: var(--white);
    box-shadow: 1px 1px 5px 0px var(--gray);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;
}

/* Logo */
.logo{
    display: inline-block;
    margin: 10px;
    width: 20%;
    height: auto;
}

/* Nav menu */
.nav{
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: var(--white);
    overflow: hidden;
}

.menu a{
    display: block;
    padding: 20px;
    color: var(--black);
}

.menu a:hover{
    background-color: var(--gray);
}

.nav{
    max-height: 0;
    transition: max-height .5s ease-out;
}



/* Menu Icon */
.hamb{
    cursor: pointer;
    float: right;
    padding: 40px 20px;
} /* Style label tag */

.hamb-line {
    background: var(--black);
    display: block;
    height: 2px;
    position: relative;
    width: 24px;
} /* Style span tag */

.hamb-line::before,
.hamb-line::after{
    background: var(--black);
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all .2s ease-out;
    width: 100%;
}
.hamb-line::before{
    top: 5px;
}
.hamb-line::after{
    top: -5px;
}

.side-menu {
    display: none;
} /* Hide checkbox */


/* Toggle menu icon */
.side-menu:checked ~ nav{
    max-height: 100%;
}
.side-menu:checked ~ .hamb .hamb-line {
    background: transparent;
}
.side-menu:checked ~ .hamb .hamb-line::before {
    transform: rotate(-45deg);
    top:0;
}
.side-menu:checked ~ .hamb .hamb-line::after {
    transform: rotate(45deg);
    top:0;
}

/* Responsiveness */
@media (min-width: 760px) {
    .nav{
        max-height: none;
        top: 0;
        position: relative;
        float: right;
        width: fit-content;
        background-color: transparent;
    }
    
    .menu li{
        float: left;
    }
    
    .menu a:hover{
        background-color: transparent;
        color: var(--gray);

    }

    .hamb{
        display: none;
    }
}