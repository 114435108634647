.card-tutorial {
    border-radius: 20px;
    border: 1px solid black;
    padding: 20px;
    width: 150px;
    height: 150px;
    align-self: start;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.image-tutorial {
    /* border-radius: 20px; */
    /* border: 1px solid black; */
    /* padding: 20px; */
    align-self: start;
    /* display: block;
    margin-left: auto;
    margin-right: auto; */
}