.img-contact {
    border-radius: 5px;
    width: 75%;
    margin: auto;
    display: block;
    padding: 10px;
}

.txt-it {
    font-size: medium;
    font-style: italic;
}

.txt-inf {
margin-left: 25px;
}

.txt-bd {
    font-weight: bold;
}

.ft-pc {
    border: 3px solid #fff;
    padding: 20px;
    margin-right: 20px;
    text-align:end;
    display:block;
}

.ft-child {
    float: left;
    padding: 20px;
}

.ft-pc-center {
    border-radius: 25px;
    width: 50%;
    margin:auto;
    text-align:center;
    display:block;
  }