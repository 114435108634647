:root {
  --white: #f9f9f9;
  --black: #36383F;
  --gray: #85888C;
}

/* variables*/

/* Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  /* height: 750px; */
  background-position: center top;
  background-size: cover;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

.row::after {
  content: "";
  clear: both;
  display: block;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}


/* Slogan */
.slogan1 {
  margin-top: 20px;
  margin-left: 20px;
  color: yellow;
  font-size: 5vw;
}

.slogan2 {
  margin: 20px;
  color: white;
  font-size: 5vw;
}

.slogan3 {
  margin: 20px;
  color: blue;
  font-size: 5vw;
}

.row-3-img {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
  text-align: center;
}

/* Create 3 equal columns that sits next to each other */
.col-4-img {
  flex: 33.33%;
  padding: 0 1px;
  text-align: center;
}

.col-4-img img {
  margin-top: 1px;
  vertical-align: middle;
  width: 100%;
}

/* Responsive layout - makes one columns stack on top of each other instead of next to each other */
@media screen and (max-width: 760px) {
  .col-4-img {
    flex: 100%;
    max-width: 100%;
  }
}

/* Container holding the image and the text */
.container-id-img {
  position: relative;
  text-align: center;
  color: white;
}

/* Centered text */
.centered-id-im {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background: rgb(0,0,0, 0.25); */
}

/* .zoom {
    transition: .2s;
    margin: 0 auto;
}

.zoom:hoover {
    -ms-transform: scale(1.1);
    -webkit-transform: scale(1.02);
    transform: scale(1.1);
} */

.bck-dlr {
  width: 100%;
  background-color: aliceblue;
  text-align: center;
}

.bck-img {
  width: 33%;
  text-align: center;
  margin: auto;
}

.responsive-id-img {
  width: 75%;
  height: auto;
}

/* Responsive layout - makes one columns stack on top of each other instead of next to each other */
@media screen and (max-width: 760px) {
  .responsive-id-img {
    flex: 100%;
    width: 100%;
  }
}

.pmarg {
  margin-left: 40px;
  margin-right: 40px;
}

.sz-form {
  width: 30%;
  margin:auto;
  border-style: solid;
  border-color: #007AD9;
  border-radius: 10px;
}

.blt-marg {
  margin-left: 20px;
  margin-right: 20px;
}

.black-bullet {
  list-style-type: square;
}

.font-subtit {
  font: bold 18px/30px Arial, sans-serif;
  color: #007AD9;
}

.font-register-equip {
  font: bold 18px/30px;
  color: #007AD9;
}

.font-register-equip-class {
  font-style: italic;
  font-size: small;
  color: #007AD9;
}

.font-note {
  font-size: small;
  font-style: italic;
}

.font-form-color {
  color: #007AD9;
}

.bd-it-font {
  font-weight:bold;
  font-style: italic;
}

.it-font {
  font-style: italic;
}

.txt-ctr {
    margin:auto;
    text-align:center;
    display:block;
    color: white;
}

.btn-lnk-blue {
  background-color: #007AD9;
  border-radius: 5px;
  width: 75%;
  margin:auto;
  display:block;
}

.btn-lnk-blue-left {
  background-color: #007AD9;
  border-radius: 10px;
  width: 15%;
}

.btn-lnk-blue-center {
  background-color: #007AD9;
  border-radius: 25px;
  width: 15%;
  margin:auto;
  display:block;
}

/* .footer-after { 
  position: absolute; 
  bottom: 0; 
  left: 0; 
  z-index: 10;
} */

/* Style the footer with menu on the right side */
.footer {
  /* position: absolute; 
  bottom: 0;
  z-index: 1; */
  background-color: var(--white);
  text-align: right;
  padding: 10px;
  width: 100%;
}

.footer-menu ul {
  list-style: none;
}

.footer-menu li {
  display: inline;
  margin-left: 5px;
  margin-right: 5px;
}

.footer-menu a {
  text-decoration: none;
  color: black;
}