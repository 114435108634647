* {
    box-sizing: border-box;
}

/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}

/* Define a CSS Grid layout */
.container {
    display: grid;
    grid-template-rows: auto 1fr auto;
    /* Header, Main, Footer */
    grid-template-columns: 1fr;
    /* Single column layout */
    min-height: 100vh;
    /* Ensure the page takes up at least the full viewport height */
}


/* CSS property for content section */
.columnA,
.columnB,
.columnC,
.columnD,
.columnE {
    float: left;
    width: 19%;
    padding: 15px;
    text-align: justify;
}

h2 {
    color: green;
    text-align: center;
}

/* Media query to set website layout
            according to screen size */
@media screen and (max-width:600px) {

    .columnA,
    .columnB,
    .columnC,
    .columnD,
    .columnE {
        float: left;
        width: 49%;
    }
}

@media screen and (max-width:400px) {

    .columnA,
    .columnB,
    .columnC,
    .columnD,
    .columnE {
        width: 85%;
    }
}

.art-gen {
    width: 100%;
    height: 200px;
    margin: 25px;
    padding: 10px;
    display: block;
    flex-direction: row;
    flex-wrap: wrap;
    float: left;
    align-content: space-between;
    border-radius: 10px;
    background-color: white;
    text-align: center;
    color: black;
    border: 2px solid black;
}

.art-gray {
    background-color: #808080;
}

.art-yellow {
    background-color: #FFFF00;
}

.art-red {
    background-color: #FF0000;
}

.art-green {
    background-color: #00FF00;
}

.art-blue {
    background-color: #00FFFF;
}

.text-italic {
    font-style: italic;
    font-size: small;
}

.img-all {
    width: 100%;
    height: auto;
}