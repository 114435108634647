.formInput {
  display: flex;
  flex-direction: column;
  width: 450px;
}

input {
  padding: 15px;
  margin: 10px 0x;
  border-radius: 5px;
  border: 1px solid gray;
}

label {
  font-size: 12px;
  color: gray;
}
