* {
    box-sizing: border-box;
}

:root{
  --white: #f9f9f9;
  --black: #36383F;
  --gray: #85888C;
} variables

.footer{
    background-color: black;
    width: 100%;
}

.ft-column {
    float: left;
    width: 25%;
    padding: 10px;
    padding-left: 25px;
    padding-top: 25px;
    background-color: black;
}

.ft-row {
    width: 100%;
    background-color: black;
}
  
/* Clear floats after the columns */
#ft-row {
    content: "";
    display: table;
    clear: both;
}

/* Responsiveness */
@media screen and (max-width: 760px) {
    .ft-column {
      width: 100%;
    }
}

.ft-h3 {
    font-family:'Times New Roman', Times, serif;
    font-size: 15px;
    color: white;
    padding-top: 5px;
    padding-bottom: 5px;
}

.ft-hr {
    border-top: 1px solid yellow;
    width: 75%;
}

.ft-ics {
  width: 50%;
  height: auto;
}

.ul-row {
  width: 95%;
  font-family:'Times New Roman', Times, serif;
  font-size: 15px;
  color: white;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
}

/* Clear floats after the columns */
#ul-loc {
    content: "";
    display: table;
    clear: both;
}

/* Clear floats after the columns */
#ul-phone {
    content: "";
    display: table;
    clear: both;
}

/* Clear floats after the columns */
#ul-fax {
    content: "";
    display: table;
    clear: both;
}

/* Clear floats after the columns */
#ul-email {
  content: "";
  display: table;
  clear: both;
}

/* Clear floats after the columns */
#ul-clock {
  content: "";
  display: table;
  clear: both;
}

.ft-icon {
  float: left;
  padding: 5px;
}

.left {
  width: 10%;
}

.right {
  width: 90%;
}

.ft-p {
  text-align: left;
  margin: auto;
}

.ul-loc {
  width: 95%;
}

.loc-int {
  float: right;
  width: 90%;
  align-items: right;
  font-family:'Times New Roman', Times, serif;
  font-size: 15px;
  color: white;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
  padding: 5px;
  margin: auto;
}


